<template>
    <el-dialog
        class="editDialog"
        :append-to-body="false"
        :modal="false"
        :fullscreen="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}宗地</div>

        <div class="content" v-loading="loading">
            <el-form class="column3" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="镇/街" prop="townId" ref="townId">
                    <Dictionary v-model="form.townId" valKey="name" code="town"></Dictionary>
                </el-form-item>

                <el-form-item label="名称" prop="name" ref="name">
                    <el-input v-model="form.name" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="宗地名称" prop="landName" ref="landName">
                    <el-input v-model="form.landName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="基本情况" prop="info" ref="info" style="width:100%;">
                    <!-- <el-input type="textarea" v-model="form.info" placeholder="请输入" @keyup.enter.native="submit"></el-input> -->
                    <quill-editor :options="editorOption" v-model="form.info"></quill-editor>
                </el-form-item>

                <el-form-item label="标题图" style="width:100%;">
                    <UploadImages v-model="form.attachmentVos" />
                </el-form-item>

                <el-form-item label="内容图" style="width:100%;">
                    <UploadList v-model="form.landSituationVos">
                        <template slot="tableColumn">
                            <el-table-column align="center" label="图片" width="100">
                                <template slot-scope="scope">
                                    <i v-if="scope.row.loading" class="el-icon-loading" style="font-size:30px;"></i>
                                    <img style="max-width:80px;max-height:80px;" v-else  :src="scope.row.fileName | fileAddress" />
                                    <!-- <UploadImages v-model="scope.row.fileName" /> -->
                                </template>
                            </el-table-column>
                            <el-table-column prop="info" align="center" label="描述">
                                <template slot-scope="scope">
                                    <!-- <el-input type="textarea" v-model="scope.row.info" placeholder="请输入" @keyup.enter.native="submit"></el-input> -->
                                    <quill-editor v-model="scope.row.info" :options="editorOption"></quill-editor>
                                </template>
                            </el-table-column>
                        </template>
                        <!-- <i slot="operation"  slot-scope="{ scope }" class="iconfont iconerror" @click="landSituationRemove(scope)"></i> -->
                    </UploadList>
                </el-form-item>

                <el-form-item label="证书类型" prop="zsType" ref="zsType"  style="width:100%;">
                    <el-input v-model="form.zsType" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="地块" style="width:100%;">
                    <el-tabs v-if="showEditDialog" type="border-card" addable @tab-add="parcelInfoAdd" @tab-remove="parcelInfoRemove" v-model="activeParcelInfo">
                        <el-tab-pane v-for="(n,i) in form.parcelInfoVos" :label="`地块${i+1}`" :name="`${i}`" :closable="form.parcelInfoVos.length>1">
                            <el-descriptions title="" :column="2"  border>

                                <el-descriptions-item>
                                    <template slot="label">批准书/用地规划许可证</template>
                                    <el-input v-model="n.pzs" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">用地单位</template>
                                    <el-input v-model="n.yddw" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">权属性质</template>
                                    <el-input v-model="n.qsxz" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">地块位置</template>
                                    <el-input v-model="n.dkwz" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">土地用途</template>
                                    <el-input v-model="n.tdyt" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">地块面积（㎡）</template>
                                    <el-input v-model="n.dkmj" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">建筑面积（㎡）</template>
                                    <el-input v-model="n.jzmj" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">容积率</template>
                                    <el-input v-model="n.rjl" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">绿地率</template>
                                    <el-input v-model="n.ldl" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">限高</template>
                                    <el-input v-model="n.xg" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">项目联系人</template>
                                    <el-input v-model="n.xmlxr" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                                <el-descriptions-item>
                                    <template slot="label">联系方式</template>
                                    <el-input v-model="n.lxfs" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                                </el-descriptions-item>

                            </el-descriptions>

                        </el-tab-pane>
                    </el-tabs>
                </el-form-item>
            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow',false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import mixinsForm from '@/mixins/form'
import {initTreeData} from "@/util/common";
import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        UploadImages: () => import("@/components/upload/Images.vue"),
        UploadList: () => import("@/components/upload/List.vue"),
        quillEditor
    },
    mixins: [mixinsForm],
    props: {
        treeselectOptions: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{},
            rules: {
                townId: [{ required: true, message: '请选择镇/街', trigger:['blur','change'] }],
                name: [{ required: true, message: '请输入名称', trigger:['blur','change'] }],
                landName: [{ required: true, message: '请输入宗地名称', trigger:['blur','change'] }],
            },
            form: {
                parcelInfoVos:[{
                    pzs:'',
                    yddw:'',
                    qsxz:'',
                }],
                landSituationVos:[],
                attachmentVos: [],
            },
            activeParcelInfo:'0',
            editorOption: {
                modules:{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
                        ]
                    },
                    placeholder: "输入内容..."
                }, //编辑器配置项
        };
    },
    created() {},
    methods: {
        parcelInfoAdd(){
            this.activeParcelInfo = this.form.parcelInfoVos.length + '';
            this.form.parcelInfoVos.push({})
        },
        parcelInfoRemove(index){
            var i  = Number(index);
            var id = this.form.parcelInfoVos[i].id;
            if(id){
                this.$confirm("确定删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.$api['admin/landUseInfo'].deleteParcelInfo({ids:[id]}).then(res => {

                        this.form.parcelInfoVos.splice(i,1);
                        if(i==this.activeParcelInfo&&i>this.form.parcelInfoVos.length-1){
                            this.activeParcelInfo = this.form.parcelInfoVos.length-1  +'';
                        }
                        this.$forceUpdate()

                    }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {//用户取消

                });
            }else {
                this.form.parcelInfoVos.splice(i,1);
                if(i==this.activeParcelInfo&&i>this.form.parcelInfoVos.length-1){
                    this.activeParcelInfo = this.form.parcelInfoVos.length-1 +'';
                }
                this.$forceUpdate()
            }

        },
        landSituationRemove(scope){
            if(scope.row.id){
                this.$confirm("确定删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.$api['admin/landUseInfo'].deleteLandSituation({ids:[scope.row.id]}).then(res => {

                        this.form.landSituationVos.splice(scope.$index,1);

                    }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {//用户取消

                });
            }else {
                this.form.landSituationVos.splice(scope.$index,1);
            }
        },
        init(options){
            if(options.id){
                this.$api['admin/landUseInfo'].getById(options.id).then(res=>{

                    var form = res.data.landUseInfo;

                    form.parcelInfoVos = res.data.parcelInfoList;
                    form.attachmentVos = res.data.attachments;

                    form.landSituationVos = [];

                    res.data.landSituations.some(r=>{
                        form.landSituationVos.push(r.combination({
                            fileName:r.attachmentVoList[0].fileName,
                            fileAddress:r.attachmentVoList[0].fileAddress,
                            attachmentId:r.attachmentVoList[0].id,
                        }))
                    });

                    this.form = form;
                    this.activeParcelInfo='0';
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            }else {
                this.form = options.combination({
                    parcelInfoVos:[{
                        pzs:'',
                        yddw:'',
                        qsxz:'',
                    }],
                    landSituationVos:[],
                    attachmentVos: [],
                });

                console.log(options.combination({
                    parcelInfoVos:[{
                        pzs:'',
                        yddw:'',
                        qsxz:'',
                    }],
                    landSituationVos:[],
                    attachmentVos: [],
                }))
                this.activeParcelInfo='0';
                this.loading = false;
            };
        },
        submit(){

            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    var data = this.form.deepClone();

                    data.landSituationVos = [];

                    for(let i=0;i<this.form.landSituationVos.length;i++){

                        let item = this.form.landSituationVos[i];

                        let obj = {
                            id:item.id,
                            sort:i,
                            info:item.info,
                            attachmentVoList:[{
                                fileAddress:item.fileAddress,
                                fileName:item.fileName,
                            }],
                        };
                        if(item.id){
                            obj.id = item.id;
                        };

                        if(item.attachmentId){
                            obj.attachmentVoList[0].id = item.attachmentId;
                        };

                        data.landSituationVos.push(obj);

                    };
                    this.submitLoading = true;

                    this.$api['admin/landUseInfo'].save(data).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
